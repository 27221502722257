<template>
    <div>
        <form class="form-inline azul header-row" action="">
           <div class="form-group col-md-3">
              <div class="label">{{tr('How booked your trip?')}}*</div>
              <select class="custom-select" name="" id="" disabled>
                 <option v-for="opt of fieldOptions['SalesUserId']" :value="opt.value"
                    :selected="opt.value == bookingForm.SalesUserId">{{opt.label}}</option>
              </select>
           </div>
           <div-input fieldName="PaxName" :v.sync="bookingForm.PaxName" :cols="3"
                label="Reservation under which name" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
           <div-input fieldName="Country" :v.sync="bookingForm.Country" :cols="3"
                label="Country" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
           <!--
           <div-input fieldName="Phone" :v.sync="bookingForm.Phone" :cols="2"
                label="Phone Number" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
           -->
           <div-input  
               fieldName="Mobile" 
               :v.sync="bookingForm.Mobile" :cols="3"
               :label="(bookingForm.CustomerType && bookingForm.CustomerType.Agency) ? 'Passenger Mobile Number' : 'Mobile Phone Number'" 
               :langCode="langCode"
               :invalid="invalid"
           ></div-input>
           <div-input fieldName="Address" :v.sync="bookingForm.Address" :cols="8"
                label="Address, City & Country" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
           <div-input fieldName="TaxNumber" :v.sync="bookingForm.TaxNumber" :cols="4"
                label="Tax Code for Invoicing" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
        </form>
        <form class="form-inline azul header-row mt-2" action="">
           <div class="form-group col-md-12 label">
              <label>{{tr('_contact_person')}}</label>
           </div>
           <div-input fieldName="ContactName" :v.sync="bookingForm.ContactName" :cols="6"
                label="Name and Surname" :langCode="langCode" :invalid="invalid"
           ></div-input>
           <div-input fieldName="ContactPhone" :v.sync="bookingForm.ContactPhone" :cols="6"
                label="Phone Number" :langCode="langCode" :invalid="invalid"
           ></div-input>
        </form>
    </div>
</template>

<script>
const reservationFormMain = importVueComp('components/reservationform', 'ReservationFormMain', 'custom');
export default {
    name: 'custom-reservation-form-main',
    mixins: [reservationFormMain],
}
</script>

