<template>
    <report-window
        endpoint="/custom/reports/taxreport"
        v-if="ready"
        :fields="fields"
        :title="cardTitle || title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :columnsClasses="columnsClasses"
        :notFilterable="options && options.notFilterable"
        :headerColumns="headerColumns"
        :current.sync="current"
        :slotsFields="slotsFields"
        :totalsOn="totalsOn"
        :countOn="countOn"
        :fieldClasses="fieldClasses"
        :columnsFormat="columnsFormat"
        :columnClick="columnClick"
        :beforeRender="beforeRender"
        :requestTimeout="120000"
    ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'tax-report',
    props: [
        'options', 
        'cardTitle'
    ],
    components: {
        ReportWindow,
    },  
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
        columnsClasses () {
            return {
                Total: 'text-right',
                TaxAmount: 'text-right',
                Difference: 'text-right',                
            }
        },
        totalsOn () {
            if (!this.showTotals) return [];
            let r = [ 'Total', 'TaxAmount', 'Difference'];
            return r;
        }
    },
    mounted () {
        this.current = Object.assign({}, this.current);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        return this.getData(this);
    },
    methods: {
        getData (self) {
            return {
                current: {},
                currencies: api.tables.currency,
                showTotals: true,
                ready: false,
                fields: [
                    { 
                        name: 'FromDate', 
                        editor: 'date', 
                        label: 'From Date'
                    },
                    { 
                        name: 'ToDate', 
                        editor: 'date', 
                        label: 'To Date'
                    },
                    { 
                        name: 'FilterDate', 
                        label: 'Filter Date by', 
                        editor: 'select', 
                        addBlank: true, 
                        required: true,
                        options: [  
                            {value: '0', label: 'Sales Date'},
                            {value: '1', label: 'Date Inn'},
                            {value: '2', label: 'Date Out'},
                        ]
                    },
                ],
                title: 'Tax Report',
                headers: {
                    BookingId: 'File Nr',
                    PaxName: 'Passenger',
                    StartDate: 'Date Inn',
                    Total: 'Total',
                    Tax: 'Tax',
                    TaxAmount: 'Tax Amount',
                },
                templates: [
                    {
                        name: "StartDate",
                        callback: (row) => {
                            if (!row.StartDate) return "";
                            return moment(row.StartDate).format("DD/MM/YYYY");
                        }
                    },
                    {
                        name: "EndDate",
                        callback: (row) => {
                            if (!row.EndDate) return "";
                            return moment(row.EndDate).format("DD/MM/YYYY");
                        }
                    },
                    {
                        name: "ConfirmedDate",
                        callback: (row) => {
                            if (!row.ConfirmedDate) return "";
                            return moment(row.ConfirmedDate).format("DD/MM/YYYY");
                        }
                    },
                    {
                        name: 'Total', callback: (row) => {
                            if (!row.Total) return "";
                            return tools.toNumber(row.Total, 0);
                        }
                    },
                    {
                        name: 'TaxAmount', callback: (row) => {
                            if (!row.TaxAmount) return "";
                            return tools.toNumber(row.TaxAmount, 0);
                        }
                    },
                    {
                        name: 'Difference', callback: (row) => {
                            if (!row.Difference) return "";
                            return tools.toNumber(row.Difference, 0);
                        }
                    }
                ],
                slotsFields: [],
                countOn: [ 'PaxName'],
                fieldClasses: {
                    BookingId: (row) => {
                        return 'cursor-pointer-report';
                    },
                },
                columnsFormat: {},
                columnClick: {
                    BookingId: self.showBooking,
                },
            }
        },
        getFieldStyle (row) {},
        getQuoted (row) {
            if (row.calculated && row.Quoted) {
                return row.Quoted;
            }
            let v = row.QuotedSalesPrice;
            if (row.ReceiptsSalesPrice) v = row.ReceiptsSalesPrice;
            return v;
        },
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-booking', {id: row.BookingId.toString(), TemplateType: 'NONE'});
        },
        async beforeRender (report) {}
  }
}
</script>

