<template>
    <form class="form-inline celeste header-row" action="">
       <div-input fieldName="pax.FirstName" :v.sync="pax.FirstName" :cols="2"
            label="First Name" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.MiddleName" :v.sync="pax.MiddleName" :cols="2"
            label="Second Name" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.LastName" :v.sync="pax.LastName" :cols="2"
            label="Surname" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.Email" :v.sync="pax.Email" :cols="3"
            label="Email" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div class="form-group col-md-3">
            <date-selector
                :def.sync="pax.BirthDate"
                format="YYYY-MM-DD"
                :id="'BirthDate-' + i"
                :label="tr('Date of Birth') + '*'"
                :langCode="langCode"
                :disableFuture="true"
                :invalid.sync="datesInvalids['BirthDate-' + i]"
          ></date-selector>
       </div>
       <div-input fieldName="pax.Passport" :v.sync="pax.Passport" :cols="3"
            label="Valid Passport Number" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div class="form-group col-md-3">
            <date-selector
                :def.sync="pax.PassportValidTo"
                format="YYYY-MM-DD"
                :id="'PassportValidTo-' + i"
                :label="tr('Passport Expiration Date') + '*'"
                :langCode="langCode"
                :disablePast="true"
                :invalid.sync="datesInvalids['PassportValidTo-' + i]"
          ></date-selector>
       </div>

       <div class="form-group col-md-3">
            <div class="label">{{tr('Country of Origin of Passport')}}*</div>
            <select class="custom-select"
                :class="(!pax.PassportCountry && invalid && isRequired('pax.PassportCountry'))? 'is-invalid': ''"
                :required="isRequired('pax.PassportCountry')"
                v-model="pax.PassportCountry">
                <option
                    v-for="opt of countries"
                    :value="opt.value"
                    :selected="opt.value == pax.PassportCountry">
                    {{tr(opt.label)}}
                </option>
          </select>
       </div>
       <div class="form-group col-md-3">
            <div class="label">{{tr('Room Type?')}}*</div>
            <div class="col-12 p-0 m-0"
                :class="(!pax.RoomType && invalid && isRequired('pax.RoomType'))? 'is-invalid': ''"
                :required="isRequired('pax.RoomType')">
                <image-select
                    :currentValue.sync="pax.RoomType"
                    :options="fieldOptions.RoomType"
                    :invalid="invalid"
                ></image-select>
            </div>
       </div>
       <div class="form-group col-md-6">
           <div class="label">{{tr('Add Passport')}}</div>
           <input type="file" class="form-control" @change="uploadPassport($event, i)" :id="'pass-' + i" multiple>
           <i class="text-white">{{tr('Maximum allowed file size')}} {{maxSize}} Mb</i>
       </div>
    </form>
</template>

<script>

const reservationFormPax = importVueComp('components/reservationform', 'ReservationFormPax', 'custom');
export default {
    name: 'custom-reservation-form-pax',
    mixins: [reservationFormPax],
}
</script>

