<script>
import '@/extra/custom/css/r-style.scss';
const reservationForm = importVueComp('components/reservationform', 'ReservationForm', 'custom');
let requiredFields = reservationForm.data().requiredFields;
requiredFields.push('ContactName');
requiredFields.push('ContactPhone');
requiredFields.push('Address');
requiredFields.push('TaxNumber');
export default {
    name: 'custom-reservation-form',
    mixins: [reservationForm],
    data () {
        return {
            accept: true,
            maxSize: 10,
            requiredFields: requiredFields,
        }
    },
    mounted () {
        this.accept = true;
        this.flightsMessage = this.tr('It is an essential requirement to attach a copy of international flights') + '. '
          + this.tr('Passenger are responsible to comuncate to Raja Tours Indonesia any changes in the schedule of international flight');
    },
    methods: {
        /*async onMount () {
            if (this.paramRequiredFields) {
                this.requiredFields = this.paramRequiredFields;
            }
            let record = await api.get(this.endpoint, {filters: JSON.stringify({Hash: this.hash, BookingId: parseInt(this.id)})});
            if (record) {
                if (record[0].attachments) this.attached = record[0].attachments;
                let model = api.importMixinModule('bookingform', 'model');
                this.bookingForm = await new model({tableName: 'bookingform', record: record[0], notFieldOptions: true});
                this.bookingForm.original_id = this.bookingForm.id;
                if (this.bookingForm.BookingFormPaxs.length==0){
                    this.bookingForm.addRow({fieldName: 'BookingFormPaxs'});
                }
                let EntryFlight = _.find(this.bookingForm.BookingFormFlights, (c) => c.FlightType=='ENTRY_FLIGHT')
                if (!EntryFlight) {
                    this.bookingForm.addRow({fieldName: 'BookingFormFlights', values: {FlightType: 'ENTRY_FLIGHT'}});
                }
                let DepartureFlight = _.find(this.bookingForm.BookingFormFlights, (c) => c.FlightType=='DEPARTURE_FLIGHT')
                if (!DepartureFlight) {
                    this.bookingForm.addRow({fieldName: 'BookingFormFlights', values: {FlightType: 'DEPARTURE_FLIGHT'}});
                }

                let DomesticFlight = _.find(this.bookingForm.BookingFormFlights, (c) => c.FlightType=='DOMESTIC')
                if (!DomesticFlight) {
                    this.bookingForm.addRow({fieldName: 'BookingFormFlights', values: {FlightType: 'DOMESTIC'}});
                }
                let user = await api.get('/public/api/user/' + this.bookingForm.SalesUserId, {GetAccessGroup: false});
                if (user) {
                    this.fieldOptions = {
                        SalesUserId: [{label: user.FirstName, value: user.id}],
                        RoomType: await this.getRoomTypesBookingForm(),
                    }
                }
                this.ready = true;
            }
        },*/
    }
}
</script>
