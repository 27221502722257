const modules = vueTools.importModule('modules', 'tools', 'custom').default;

modules.settingsMenu.endpoints.push('othercondition');
modules.settingsMenu.endpoints.push('emergencycontact');
modules.settingsMenu.endpoints.push('markup');
modules.settingsMenu.endpoints.push('region');
modules.settingsMenu.endpoints.push('documentation');
modules.salesMenu.endpoints.push({endpoint: 'report', label: 'Find Files', componentName: 'BookingListReport', report: 'bookinglists'})
modules.salesMenu.endpoints.push({endpoint: 'report', label: 'Pax Calendar', componentName: 'PaxCalendarReport', report: 'paxcalendar'})
modules.salesMenu.endpoints.push({table: 'booking', endpoint: 'abm-booking-catalogue', label: 'Catalogue', path: 'bookingcatalogue'},)
modules.salesMenu.endpoints.push({endpoint: 'report', label: 'Destinations List', componentName: 'DestinationListReport', report: 'destinationlist'})
modules.reportsMenu.endpoints.push({endpoint: 'report', label: 'Sales by Island', componentName: 'SalesByIslandReport', report: 'salesbyisland'})
modules.reportsMenu.endpoints.push({endpoint: 'report', label: 'Tax Report', componentName: 'TaxReport', report: 'taxreport'});
modules.operMenu.endpoints.push({endpoint: 'report', label: 'Pax Calendar', componentName: 'PaxCalendarReport', report: 'paxcalendar2'});
modules.adminMenu.endpoints.push('invoice');


export default modules;
