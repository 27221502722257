<template>
    <div class="card no-border-top">
        <abm v-if="ready"
        endpoint="/api/invoice/"
        :options="options"
        tableName="invoice">
        </abm>
    </div>
</template>


<script>
import abm from '@/components/tools/abm';
import { mapState } from "vuex";
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'bookingInvoice',
    components: {
        abm,
    },
    data () {
        let self = this;
        return {
            options: null,
            ready: false,
        };
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
        }),
    },
    async mounted () {
        let options = await api.getModuleFieldsOptions('invoice');
        this.options = _.cloneDeep(options);
        this.options.filters = {'BookingId': this.recordStore.id};
        this.options.serverSide = false;
        this.options.showRecord = async (id) => {
            await this.$nextTick();
            this.$root.$children[0].openModalRecord({modalRecord: null, modalTable: 'invoice', modalId: id, closeAction: 'close-invoice'});
        }
        this.options.addNew = () => {
            let id = 'new';
            let text = `${moment(this.recordStore.StartDate).format('DD/MM/YYYY')} to ${moment(this.recordStore.EndDate).format('DD/MM/YYYY')}. ${this.recordStore.Title} (${tr('Booking Number')}: ${this.recordStore.id}).`;
            let modalRecord = {
                BookingId: this.recordStore.id,
                InvoiceDate: moment().format('YYYY-MM-DD'),
                InvType: 'INVOICE',
                InvoiceDetails: [],
            }
            for (let t of this.recordStore.bookingTotals){
                modalRecord.InvoiceDetails.push({
                    Detail: text,
                    Amount: this.totalByBasePax(t.Base, t.pType, t.Quote.Price),
                    Pax: this.getPaxTotalRow(t),
                })
            }
            this.$root.$children[0].openModalRecord({modalRecord, modalTable: 'invoice', modalId: id, closeAction: 'close-invoice'});
        }
        this.options = Object.assign({}, this.options)
        this.ready = true;
        EventBus.$on('close-invoice', this.updateList);
    },
    methods: {
        getPaxTotalRow (t) {
            return this.recordStore.getPaxBasePaxType(t.Base, t.pType);
        },
        updateList () {
            this.ready = false;
            setTimeout(() => {this.ready = true}, 1)
        },
        totalByBasePax (base, pType, value) {
            let t = value;
            if (this.totalFlightsPax && this.totalFlightsPax[base] && this.totalFlightsPax[base][pType]) {
                t += this.totalFlightsPax[base][pType].Price;
            }
            let res = this.recordStore.getTotalInCurrency(t);
            return res;
        },

    },
    beforeDestroy() {
        EventBus.$off('close-invoice', this.updateList);
    },
};
</script>
