<template>
    <div class="modal fade bd-example-modal-lg" id="booking-notes" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="templateBookingVersions" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
          <h5 class="modal-title">{{tr('Notes')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body form-material" v-if="loaded">
              <div class="form-row" v-for="(row, idx) of filterNotes" :class="getBGClass(idx)">
                  <input v-model="notes[idx]['id']" type="hidden">
                  <div class="w-100">
                      <booking-note
                          :replyToUserId="row.CreatedUserId"
                          :row="row"
                          @editNote="editNote"
                          @setImportant="setImportant"
                          @deleteNote="deleteNote"
                          @reply="reply"
                          :canRemove="canRemove(row)"
                      ></booking-note>
                      <div class="pl-5" v-for="nRow of replies(row)">
                          <booking-note
                              :replyToUserId="nRow.CreatedUserId"
                              :mainId="row.id"
                              class="border-top border-dark pt-1"
                              :row="nRow"
                              @editNote="editNote"
                              @setImportant="setImportant"
                              @deleteNote="deleteNote"
                              @reply="reply"
                              :canRemove="canRemove(nRow)"
                          ></booking-note>
                      </div>

                  </div>
                  <!--div class="col-md-12 my-1">
                      <span class="mr-1 font-weight-bold">{{row.CreatedUser.UserName}}:</span>
                      <span class="mr-1 font-weight-bold" v-if="row.UserId">@{{row.User.UserName}}</span>
                      <span>{{row.Note}}</span>
                      <span v-if="row.SupplierId" class="mr-1 font-italic font-weight-bold"><br>{{tr('supplier')}}: {{row.Supplier.Name}}</span>
                      <div class="d-flex justify-content-between align-items-center mt-1">
                        <span>{{dateFormat(row.CreatedUTC)}}</span>
                            <div class="mr-3 ">
                                <i class="add-item cursor-pointer" @click="editNote(idx)">
                                   <font-awesome-icon icon="edit" size="xs"/>
                                </i>
                                <i class="add-item cursor-pointer ml-4" @click="setImportant(idx)">
                                   <font-awesome-icon icon="bell" size="xs" :class="{'row-primary-button': row.Important}"/>
                                </i>
                                <i class="add-item cursor-pointer ml-4 text-danger" @click="deleteNote(idx)" v-if="canRemove(row)">
                                   <font-awesome-icon icon="trash-alt"  size="xs"/>
                                </i>
                            </div>
                      </div>
                  </div-->

              </div>
              <i class="add-item cursor-pointer" @click="newNote">
                  <font-awesome-icon icon="plus-circle"/>
              </i>
          </div>
          <div v-else class="loading-div"><img class="loading-gif" src="@/img/loading.gif"></div>
        </div>
        <input-note
            v-if="showInputValue"
            @save="addNote"
            @close="closeInputValue"
            type="memo"
            :def="def"
            :text="title"
            :defSupplierId="defSupplierId"
            :defUserId="defUserId"
            :replyId="replyId"
        ></input-note>
      </div>
    </div>
</template>

<script>
const inputNote = importVueComp('components/tools', 'InputNote');
const escape = importVueComp('components/tools', 'Escape');
const bookingNote = importVueComp('components/booking', 'BookingNote');
import { mapState } from 'vuex';
export default {
    name: 'booking-notes',
    props: ['show'],
    mixins: [escape],
    props: ['id'],
    components: {
      'input-note': inputNote,
      'booking-note': bookingNote,
    },
    data () {
        return {
            loaded: false,
            notes: [],
            showInputValue: false,
            def: null,
            currentNote: null,
            defSupplierId: null,
            defUserId: null,
            replyId: null,
            replyToUserId: null,
        }
    },
    mounted: async function(){
        $('#booking-notes').modal({backdrop: 'static', keyboard: false}, 'show');
        let notes = await api.get('/api/bookingnote/', {IncludeClosed: false
            , filters: JSON.stringify({BookingId: this.id})})
        if (notes) {
            this.notes = _.filter(notes, (r) => !r.Closed);
            this.loaded = true;
        }
        await this.$nextTick();
        let rows = $('.note-text-area');
        let i = 0;
        for (let row of rows) {
            $(row).attr('rows', parseInt(this.notes[i].Note.length / 50));
            i += 1;
        }
    },
    methods: {
        async deleteNote (id) {
            let i = _.findIndex(this.notes, (r) => r.id == id);
            let n = _.cloneDeep(this.notes[i]);
            n.Closed = true;
            n.original_id = n.id;
            let res = await api.post('/api/bookingnote/' + n.id, JSON.stringify(n));
            if (res) {
                this.notes[i] = res;
                this.notes = Object.assign([], _.filter(this.notes, (r) => !r.Closed));
            }
        },
        async editNote (id) {
            let i = _.findIndex(this.notes, (r) => r.id == id);
            let n = _.cloneDeep(this.notes[i]);
            this.def = n.Note;
            this.currentNote = n;
            this.showInputValue = true;
            if (this.currentNote.SupplierId) this.defSupplierId = this.currentNote.SupplierId;
            if (this.currentNote.UserId) this.defUserId = this.currentNote.UserId;
        },
        async setImportant (id) {
            let i = _.findIndex(this.notes, (r) => r.id == id);
            let n = _.cloneDeep(this.notes[i]);
            n.Important = !n.Important;
            if (n.Important) n.Status = 'CREATED';
            if (!n.Important) n.Status = 'READED';
            n.original_id = n.id;
            let res = await api.post('/api/bookingnote/' + n.id, JSON.stringify(n));
            if (res) {
                this.notes[i] = res;
                this.notes = Object.assign([], _.filter(this.notes, (r) => !r.Closed));
            }
        },
        async addNote (value, supplierId, userId, replyId){
            let n = {};
            n.Note = value;
            n.Status = 'CREATED';
            n.Closed = false;
            n.SupplierId = supplierId;
            n.UserId = userId;
            n.NoteReplyId = replyId;
            n.Datetime = moment(new Date(), "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");
            n.BookingId = this.id;
            n.id = null;
            n.syncVersion = null;
            if (this.currentNote) {
                n.id = this.currentNote.id;
                n.syncVersion = this.currentNote.syncVersion;
            }
            let res = await api.post('/api/bookingnote/0', JSON.stringify(n));
            if (res) {
                if (!this.currentNote) {
                    this.notes.push(res);
                } else {
                    let idx = _.findIndex(this.notes, (r) => r.id == this.currentNote.id);
                    if (idx > -1) this.notes[idx] = res;
                }
                this.notes = Object.assign([], this.notes);
                this.closeInputValue();
            }
        },
        reply (id, replyToUserId) {
            let i = _.findIndex(this.notes, (r) => r.id == id);
            let n = this.notes[i];
            this.replyId = n.id;
            this.defUserId = replyToUserId;
            this.showInputValue = true;
        },
        dateFormat (d) {
            return moment.utc(d).local().format("DD/MM/YYYY HH:mm");
        },
        newNote () {
            this.replyId = null;
            this.defUserId = null;
            this.replyToUserId = null;
            this.showInputValue = true;
        },
        close () {
            this.$emit('update:show', false);
            $('#booking-notes').modal('hide');
        },
        closeInputValue () {
            this.showInputValue = false;
            this.currentNote = null;
            this.def = null;
        },
        getBGClass (idx) {
            if (idx % 2 == 0) return 'bg-silver';
            return '';
        },
        canRemove (row) {
            return true
        },
        replies (row) {
            return _.filter(this.notes, (r) => r.NoteReplyId == row.id);
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        title () {
            return this.currentNote? 'Edit Note': 'Add New Note';
        },
        filterNotes () {
            return _.filter(this.notes, (n) => {
                if (!n.NoteReplyId) return true;
                if (!n.UserId) return true;
                if (n.CreatedUserId == this.user.id) return true;
                if (n.UserId == this.user.id) return true;
            })
        }
    }
}
</script>
