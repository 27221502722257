<script>
const bookingNavItems = importVueComp('components/booking', 'BookingNavItems', 'custom');
export default {
  name: 'custom-booking-nav-items',
  extends: bookingNavItems,
  computed: {
      canViewExpenseTabs () {
          return tools.canAccess(this.user, 'view','GET', 'expenses_tab', false);
      }
  },
  methods: {
    getItems () {
      this.itemsList = this.getItemList();
      let expensesItem = _.find(this.itemsList, (r) => r.id == 'expenses');
      if (expensesItem) {
        expensesItem.vIf = () => {return !this.isTemplate && this.canViewExpenseTabs};
      }
      this.itemsList.push({
        id: 'documentation',
        label: 'Documentation',
        vIf: () => { return this.showDocumentation },
        showTabs: () => { return this.showTabs }
      });
      this.itemsList.push({
        id: 'invoicing',
        label: 'Invoicing',
        vIf: () => { return this.bookingConfirmed },
        showTabs: () => { return this.showTabs }
      });

      this.setItems();
    }
  }
}
</script>
