<template>
  <form class="form-inline celeste header-row" action="">
     <div class="form-group" :class="{'col-md-4': flight.FlightType!='DOMESTIC', 'col-md-3': flight.FlightType=='DOMESTIC'}">
        <div class="label">{{tr(dateTimeLabel)}}</div>
        <m-date-time type="datetime" :value.sync="flight.FlightDate" format="YYYY-MM-DDTHH:mm:ss"></m-date-time>
     </div>
     <div :class="getClass">
        <div class="label">{{tr('Airline and flight number')}}</div>
        <input class="form-control" type="text" v-model="flight.FlightNumber">
     </div>
     <div :class="getClass" v-if="flight.FlightType!='ENTRY_FLIGHT'">
        <div class="label">{{tr('Departure city')}}</div>
        <input class="form-control" type="text" v-model="flight.DepartureCity">
     </div>
     <div :class="getClass" v-if="flight.FlightType!='DEPARTURE_FLIGHT'">
      <div class="label" v-if="flight.FlightType=='ENTRY_FLIGHT'">{{tr('Indonesia Arrival city')}}</div>
      <div class="label" v-else>{{tr('Arrival city')}}</div>
      <input class="form-control" type="text" v-model="flight.ArrivalCity">
     </div>
     <div class="form-group col-md-3" v-if="flight.FlightType!='DOMESTIC' || attachDomestic">
        <div class="label">{{tr('Add Ticket')}}
           <i class="text-white ml-2">({{tr('Max. allowed size')}} {{maxSize}} Mb)</i>
        </div>
        <input type="file" class="form-control" @change="uploadTicket($event)" :id="'flight-' + i" multiple>
      </div>

 </form>
</template>

<script>
const reservationFormFlight = importVueComp('components/reservationform', 'ReservationFormFlight', 'custom');
export default {
    name: 'reservation-form-flight',
    mixins: [reservationFormFlight],
    data () {
        return {
            attachDomestic: true,
        }
    },
    computed: {
        dateTimeLabel () {
            let text = 'Date and time of flight';
            if (this.flight.FlightType=='ENTRY_FLIGHT') return text + ' arrival';
            if (this.flight.FlightType=='DEPARTURE_FLIGHT') return text + ' departure';
            return text;
        },
    }

}
</script>