<template>
    <div id="b-invoice" v-if="ready" @contextmenu="handleClick($event)">
        <div class="button-fixed">
            <button type="button" class="btn btn-primary action-button"
                @click="print()">
                <font-awesome-icon icon="print"/>
            </button>
        </div>
        <div class="row ml-2 divPrint report-header text-center">
            <div class="col-12">
                <img id="logo" src="@/extra/custom/img/logo-full.png" class="py-0">
            </div>
        </div>

        <table class="w-100 container" :style="image">
          <thead class="">
            <tr>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr><td class="container" v-if="recordStore" id="voucher">
                <div class="row not-print text-center">
                    <div class="col-12">
                        <img id="logo" src="@/extra/custom/img/logo-full.png" class="py-0">
                    </div>
                </div>
                <booking-invoice-data
                    :payment="payment"
                    :receipts="receipts"
                ></booking-invoice-data>
                <booking-invoice-payment
                    :payment="payment"
                    v-if="payment && receipts.length > 0"
                    :receipts="receipts"
                ></booking-invoice-payment>
                <div class="row mt-2 footer-text not-print">
                    <div class="col-12">
                        <span>P.T. RAJA PARIWISATA INDONESIA</span>
                    </div>
                    <div class="col-12">
                        <span>JALAN KRAGILAN-POTRONANGGAN.</span>
                    </div>
                    <div class="col-12">
                        <span>TAMANAN. BANGUNTAPAN. BANTUL.</span>
                    </div>
                    <div class="col-12">
                        <span>DAERAH ISTIMEWA YOGYAKARTA.</span>
                    </div>
                    <div class="col-12">
                        <span>INDONESIA 55191</span>
                    </div>
                    <div class="col-12">
                        <span>PHONE: +62 274 - 2871827</span>
                    </div>
                    <div class="col-12">
                        <span>info@rajatoursindonesia.com</span>
                    </div>
                    <div class="col-12">
                        <span>www.rajatoursindonesia.com</span>
                    </div>
                </div>
            </td></tr>
          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tfoot>

        </table>
        <div class="row ml-2 footer-text divPrint report-footer">
            <div class="col-12">
                <span>P.T. RAJA PARIWISATA INDONESIA</span>
            </div>
            <div class="col-12">
                <span>PROTONANGGAN 2 STREET NO. 80.</span>
            </div>
            <div class="col-12">
                <span>BANGUNTAPAN BANTUL, YOGYAKARTA</span>
            </div>
            <div class="col-12">
                <span>INDONESIA 55191</span>
            </div>
            <div class="col-12">
                <span>PHONE: +62 274 - 2871827</span>
            </div>
            <div class="col-12">
                <span>info@rajatoursindonesia.com</span>
            </div>
            <div class="col-12">
                <span>www.rajatoursindonesia.com</span>
            </div>
        </div>
    </div>
</template>

<script>
let bookingInvoice = importVueComp('components/documentation', 'BookingInvoice', 'custom');
let bookingInvoiceData = importVueComp('components/documentation', 'BookingInvoiceData');
let bookingInvoicePayment = importVueComp('components/documentation', 'BookingInvoicePayment');
export default {
    name: 'custom-booking-invoice',
    mixins: [bookingInvoice],
    components: {
        'booking-invoice-data': bookingInvoiceData,
        'booking-invoice-payment': bookingInvoicePayment,
    },
    data () {
        return {
            receipts: [],
        }
    },
    async mounted () {
    },
    methods: {
        async setReceipts () {
            //await api.setReceipts(this.recordStore.id);
            //let res = api.receipts;
            let res = await api.get('/api/bookingreceipt/bybookingid/' + this.recordStore.id);
            if (res) {
                this.receipts = res;
            }
        },
    },
    asyncComputed: {
        async image () {
        }
    },
    computed: {
    },
    watch: {
        ready () {
            if (this.ready) {
                this.setReceipts();
            }
        }
    }

}
</script>

<style scoped>
  .table-bordered {
      border: 2px solid #000;
  }
  .table-bordered td {
      border: 2px solid #000;
  }
</style>