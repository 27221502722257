<template>
    <div id="b-invoice" v-if="ready" @contextmenu="handleClick($event)">
        <div class="button-fixed">
            <button type="button" class="btn btn-primary action-button"
                @click="print()">
                <font-awesome-icon icon="print"/>
            </button>
        </div>
        <div class="row ml-2 divPrint report-header text-center">
            <div class="col-12">
                <img id="logo" src="@/extra/custom/img/logo-full.png" class="py-0">
            </div>
        </div>

        <table class="w-100 container" :style="image">
          <thead class="">
            <tr>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr><td class="container" v-if="recordStore" id="voucher">
                <div class="row not-print text-center">
                    <div class="col-12">
                        <img id="logo" src="@/extra/custom/img/logo-full.png" class="py-0">
                    </div>
                </div>
                <booking-invoice-data
                    :invoice="invoice"
                    :payment="payment"
                    :receipts="receipts"
                ></booking-invoice-data>
                <booking-invoice-payment
                    :payment="payment"
                    v-if="payment && receipts.length > 0"
                    :receipts="receipts"
                ></booking-invoice-payment>
                <div class="row mt-2 footer-text not-print">
                    <div class="col-12">
                        <span>P.T. RAJA PARIWISATA INDONESIA</span>
                    </div>
                    <div class="col-12">
                        <span>JALAN KRAGILAN-POTRONANGGAN.</span>
                    </div>
                    <div class="col-12">
                        <span>TAMANAN. BANGUNTAPAN. BANTUL.</span>
                    </div>
                    <div class="col-12">
                        <span>DAERAH ISTIMEWA YOGYAKARTA.</span>
                    </div>
                    <div class="col-12">
                        <span>INDONESIA 55191</span>
                    </div>
                    <div class="col-12">
                        <span>PHONE: +62 274 - 2871827</span>
                    </div>
                    <div class="col-12">
                        <span>info@rajatoursindonesia.com</span>
                    </div>
                    <div class="col-12">
                        <span>www.rajatoursindonesia.com</span>
                    </div>
                </div>
            </td></tr>
          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tfoot>

        </table>
        <div class="row ml-2 footer-text divPrint report-footer">
            <div class="col-12">
                <span>P.T. RAJA PARIWISATA INDONESIA</span>
            </div>
            <div class="col-12">
                <span>PROTONANGGAN 2 STREET NO. 80.</span>
            </div>
            <div class="col-12">
                <span>BANGUNTAPAN BANTUL, YOGYAKARTA</span>
            </div>
            <div class="col-12">
                <span>INDONESIA 55191</span>
            </div>
            <div class="col-12">
                <span>PHONE: +62 274 - 2871827</span>
            </div>
            <div class="col-12">
                <span>info@rajatoursindonesia.com</span>
            </div>
            <div class="col-12">
                <span>www.rajatoursindonesia.com</span>
            </div>
        </div>
    </div>
</template>

<script>
let bookingInvoiceData = importVueComp('components/documentation', 'BookingInvoiceData');
let bookingInvoicePayment = importVueComp('components/documentation', 'BookingInvoicePayment');
import { mapState } from 'vuex';

export default {
    name: 'print-invoice',
    props: ['id', 'bookingId', 'payment'],
    data () {
        return {
            invoiceURL: null,
            resForm: null,
            booking: null,
            ready: false,
            receipts: [],
            invoice: null,
        }
    },
    components: {
        'booking-invoice-data': bookingInvoiceData,
        'booking-invoice-payment': bookingInvoicePayment,
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        agencyAddress () {
            let res = [];
            if (this.booking.Agency && this.booking.Agency.Address) {
                return this.booking.Agency.Address.split(',');
            }
            return res;
        },
        amount () {
            if (!this.payment) {
                return this.booking.PriceCurrencyId + ' ' + this.getTotalInCurrency(this.recordStore.finalTotalWithFlights).toFixed(0);
            }
            let row = _.find(this.receipts, (r) => r.id == this.payment);
            if (row) {
                return row.CurrencyId + ' ' + row.Amount.toFixed(0);
            }
        },
        getNumber () {
            if (!this.payment) {
                return this.booking.id + '-000001' ;
            }
            let width = 6;
            width -= this.payment.length;
            if ( width > 0 ){
                return this.booking.id + "-" + new Array( width + (/\./.test( this.payment ) ? 2 : 1) ).join( '0' ) + this.payment;
            }
            return this.booking.id + "-" + this.payment + "";
        },
        getDocName () {
            if (!this.payment) return 'Invoice';
            let row = _.find(this.receipts, (r) => r.id == this.payment);
            if (row && row.Amount && row.Amount<0) {
                return 'Credit Note';
            }
            return 'Invoice';
        }
    },
    asyncComputed: {
        async image () {
        }
    },
    async mounted (){
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r = await api.get('/public/api/get_booking_invoice/' + this.bookingId + '/' + this.id);
        if (r) {
            r.booking._public = true;
            this.invoice = r.invoice;
            let record = await new model({record: r.booking, notFieldOptions: true});
            this.$store.commit('initRecord', {record});
            this.booking = tools.plainObject(this.recordStore);
            if (this.payment) {
                let res = await api.get('/api/bookingreceipt/bybookingid/' + this.recordStore.id);
                if (res) this.receipts = res;
            }
            api.setProcessing(false)
            this.ready = true;
        }
    },
    methods: {
        async setReceipts () {
            //await api.setReceipts(this.recordStore.id);
            //let res = api.receipts;
            let res = await api.get('/api/bookingreceipt/bybookingid/' + this.recordStore.id);
            if (res) {
                this.receipts = res;
            }
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        getDate  (d) {
            let r = moment(d).local().locale(this.booking.Language.Code).format('MMM DD, YYYY')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        print: function() {
            print()
        },
        handleClick (event) {
            this.$root.$children[0].handleClick(event);
        }

    },
    beforeDestroy() {
        this.$store.commit('initRecord', {record: null});
    },
    watch: {
        ready () {
            if (this.ready) {
                this.setReceipts();
            }
        }
    }

}
</script>

<style scoped>
  .table-bordered {
      border: 2px solid #000;
  }
  .table-bordered td {
      border: 2px solid #000;
  }
</style>